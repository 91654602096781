import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { } from './util/index';
import AlertDialog from './modal/AlertDialog';

const InputStyle = styled.input`
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    outline: none;
    z-index: 1;
    opacity:0;
  }
`;

const DatePicker = ({ value, name = '', max = '', min = (new Date()).toDateInput(), onHandleUpdate = () => {} }) => {
  const [valueCurrent, setValueCurrent] = useState(String(value));
  const refInput = useRef();

  const clearInput = () => {
    if (refInput.current) {
      refInput.current.value = '';
      setValueCurrent('');
      onHandleUpdate();
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    const inputDate = new Date(e.target.value);
    const presentDate = new Date();

    inputDate.setHours(0, 0, 0, 0);
    presentDate.setHours(0, 0, 0, 0);

    // sprawdz, czy ktoś próbuje ustawić datę wstecz
    if (inputDate < presentDate) { 
      setValueCurrent('');
      onHandleUpdate();
      return;
    }

    // sprawdz, czy ktoś próbuje ustawić datę w przyszłość
    if (max === '') {
      const futureDate = new Date();
      futureDate.setDate(presentDate.getDate() + 365);  // blokuje terminy dostawy powyżej roku
      if (inputDate > futureDate) {
        // const lat = ['lat', 'rok', 'lata', 'lata', 'lata'];
        // const dif = inputDate.getFullYear() - presentDate.getFullYear();
        setValueCurrent('');
        onHandleUpdate();
        AlertDialog({
          title: "Sprawdź termin dostawy",
          message: `Dostawa ma zostać zrealizowany za ponad rok?`,
          color: 'yellow',
        });
        return;
      }
    }

    setValueCurrent(""+e.target.value);
    onHandleUpdate();
    return;
  };

  useEffect(() => {
    if (value == undefined)  return;

    if (value.toDateInput() == '1900-01-01') {
      setValueCurrent(new Date());
    } else {
      setValueCurrent(String(value));
    }
  }, [value]);

  return (
    <div className="relative inline-flex items-center flex-grow">
      <InputStyle
        className="flex-grow pr-8 text-sm text-gray-600 bg-white border border-gray-300 rounded outline-none focus:outline-none focus:shadow-none ring-opacity-0 ring-0 focus:ring-1"
        type="date"
        name={name}
        ref={refInput}
        onChange={onChange}
        max={max.toDateInput()}
        min={min.toDateInput()}
        value={valueCurrent.toDateInput()}
        required="required"
      />
      <button
        className={`${(valueCurrent.toDateInput() == '') ? 'hidden' : ''} absolute right-8 z-10`}
        type="button"
        onClick={clearInput}
      >
        <svg className="w-6 h-6" viewBox="-220 -250 800 800" fill="currentColor">
          <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25  0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344  113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812  113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0" />
        </svg>
      </button>
      <svg className={`${(valueCurrent.length == 0) ? '' : ''} absolute w-5 h-5 right-2 text-gray-600 group-focus:text-blue-500`} viewBox="0 0 36.447 36.447" fill="currentColor">
        <g>
          <path d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75 c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75 c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v24c0,2.343,1.907,4.25,4.25,4.25h24 c2.344,0,4.25-1.907,4.25-4.25v-24C34.474,5.855,32.567,3.948,30.224,3.948z M25.286,2.75c0-0.689,0.525-1.25,1.17-1.25 c0.646,0,1.17,0.561,1.17,1.25v4.896c0,0.689-0.524,1.25-1.17,1.25c-0.645,0-1.17-0.561-1.17-1.25V2.75z M17.206,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M9.125,2.75 c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25v4.896c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75z M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75v-22h27.5V32.198z" />
          <rect x="6.724" y="14.626" width="4.595" height="4.089" />
          <rect x="12.857" y="14.626" width="4.596" height="4.089" />
          <rect x="18.995" y="14.626" width="4.595" height="4.089" />
          <rect x="25.128" y="14.626" width="4.596" height="4.089" />
          <rect x="6.724" y="20.084" width="4.595" height="4.086" />
          <rect x="12.857" y="20.084" width="4.596" height="4.086" />
          <rect x="18.995" y="20.084" width="4.595" height="4.086" />
          <rect x="25.128" y="20.084" width="4.596" height="4.086" />
          <rect x="6.724" y="25.54" width="4.595" height="4.086" />
          <rect x="12.857" y="25.54" width="4.596" height="4.086" />
          <rect x="18.995" y="25.54" width="4.595" height="4.086" />
          <rect x="25.128" y="25.54" width="4.596" height="4.086" />
        </g>
      </svg>
    </div>
  );
};

export default DatePicker;

/** 
 * CARTMAP
 */

enum CartType {
  Normal = 'Z',
  Pattern = 'W',
}

interface CartObject {
  id: number,
  name: string,
  typ: CartType,
  ext: Object,
  ts: Object,
}

type CartKey = number;

interface CartSummary {
  price: number,
  count: number
}

class CartMap {
  #list = {};
  event = {};
  selectNormal: number = 0; // typ='Z'
  selectPattern: number = 0; // typ='W'
  summary: CartSummary = { price: 0, count: 0};

  constructor(list: CartObject[]) {
    console.log('CartMap', list);
    if (!Array.isArray(list)) return;

    for (let value of list) {
      this.#list[value[0]] = value[1];
    }
  }

  set(key: CartKey, value: CartObject) {
    if (key === undefined)
      return;
    
    this.#list[key] = value;
    this.event = { ...this.event, [key]: (+new Date) }
  }

  has(key: CartKey) {
    //console.log('cartmap has', key, String(key), this.#list[number] = {"id":1,"name":"Koszyk","typ":"Z",ts:{},ext:{}}])
    return this.#list[key] !== undefined;
  }

  get(key: CartKey) {
    return this.#list[key];
  }

  del(key: CartKey) {
    delete this.#list[key];
    delete this.event[key];
  }
  
  clear() {
    this.#list = {};
    this.event = {};
    this.selectNormal = 0;
    this.selectPattern = 0;
    this.summary = { price: 0, count: 0};
  }

  toString() {
    return this.#list;
  }

  entries(): [string, CartObject][] {
    return Object.entries(this.#list);
  }

  values(): CartObject[] {
    return Object.values(this.#list);
  }

  delete(cartId: CartKey = 0) {
    Object.getOwnPropertyNames(this.#list).forEach(key => {
      if (parseInt(key) === cartId)
        delete this.#list[key];
        delete this.event[key];
    });
  }

  getCartIds(typ: CartType): Array<number> {
    return this
      .values()
      .filter((el) => el.typ === typ)
      .map(el => el.id);
  }

  findAsTyp(typ: CartType) {
    return this
      .values()
      .find((el) => el.typ === typ);
  }

  findById(cartId: CartKey = 0) {
    const key = Object.getOwnPropertyNames(this.#list)
      .find(key => parseInt(key) === cartId);

    if (key == undefined)
      return false;

    return this.#list[key];
  }

  getNameById(cartId: CartKey = 0) {
    const key = Object.getOwnPropertyNames(this.#list)
      .find(key => parseInt(key) === cartId);

    if (key == undefined)
      return false;
      
    return this.#list[key].name;
  }

  filterAsTyp(typ: CartType) {
    return this
      .values()
      .filter((el) => el.typ === typ);
  }

  setSummary(summary: CartSummary) {
    if (this.summary.price !== summary.price || this.summary.count !== summary.count) {
      this.summary = Object.assign({}, summary);
    }
  }

  setSelect(key: CartKey, typ: CartType) {
    if (typ === CartType.Normal){
      this.selectNormal = key;
      console.log("cart select normal", key);
    }
    if (typ === CartType.Pattern) {
      this.selectPattern = key;
      console.log("cart select pattern", key);
    }
  }

  [Symbol.iterator]() {
    const keys = Object.getOwnPropertyNames(this.#list)
    const length = keys.length;
    
    return {
      next: function () {
        return this._count < length ? {
          value: [keys[this._count], this._list[keys[this._count++]]],
          done: false
        } : {
          done: true
        }
      },
      _count: 0,
      _list: this.#list
    };
  }
};

export default CartMap;

import { usePromiseTracker } from 'react-promise-tracker';
import { ThreeDots as Loader } from 'react-loader-spinner';

const Spinner = ({ area }) => {
  const { promiseInProgress } = usePromiseTracker({ area });

  return (
    <div>
      {
        (promiseInProgress === true) && (
          <div className="absolute transform -translate-x-1/2 -translate-y-1/2 w-200 h-100 top-1/2 left-1/2">
            <Loader type="ThreeDots" color="var(--primary)" height="100" width="100" />
          </div>
        )
      }
    </div>
  );
};

export const SpinnerFixed = () => (
  <div className="absolute top-0 left-0 z-30 w-full h-full bg-gray-700 bg-opacity-10">
    <div className="relative top-0 left-0 w-full h-full">
      <div className="fixed transform -translate-x-1/2 -translate-y-1/2 w-100 h-100 top-1/2 left-1/2 ">
        <Loader type="ThreeDots" color="var(--primary)" height="100" width="100" />
      </div>
    </div>
  </div>
);

export const SpinnerAbsolute = ({ noShadow = false }) => (
  <div className={`absolute top-0 left-0 z-30 w-full h-full bg-opacity-10 ${noShadow ? '' : 'bg-gray-700'}`}>
    <div className="relative top-0 left-0 w-full h-full">
      <div className="absolute transform -translate-x-1/2 -translate-y-1/2 w-100 h-100 top-1/2 left-1/2 ">
        <Loader type="ThreeDots" color="var(--primary)" height="100" width="100" />
      </div>
    </div>
  </div>
);

export const SpinnerTracker = ({ area }) => {
  const { promiseInProgress } = usePromiseTracker({ area });

  return (
    <>
      {promiseInProgress === true && (
        <div className="absolute top-0 left-0 z-30 w-full h-full bg-gray-700 bg-opacity-10">
          <div className="relative top-0 left-0 w-full h-full">
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 w-100 h-100 top-1/2 left-1/2 ">
              <Loader type="ThreeDots" color="var(--primary)" height="100" width="100" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Spinner;

import { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { default as storage } from '../util/storage';
import api from '../../components/service/api.ts';

import { GlobalDispatchContext } from '../context/global';
import { useTranslation } from 'react-i18next';

const postLogout = async () => {
  const resp = await api.postLogout();
  return resp?.data;
};

const UserProfile = ({ user }) => {
  const globalDispatch = useContext(GlobalDispatchContext);
  const { t } = useTranslation("home");
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const refList = useRef();

  const onBlurOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (refList.current && !refList.current.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 10);
  };

  const onSettings = (e) => {
    setIsOpen(false);

    if (!(/\/settings/.test(window.location.pathname))) {
      history.push('/settings');
    }
  };

  const onLogout = (e) => {
    e.preventDefault();
    setIsOpen(false);

    postLogout().then(_ => {
      // if (data?.status !== 0) return;
      globalDispatch.setSearch('');
      storage.remove('local-sort-mode');
      history.push('/login');
    });
  };

  if (!(user.firstname.length > 0 || user.lastname.length > 0)) {
    return '';
  }

  return (
    <div className="relative">
      <button
        type="button"
        // data-title={`${user.firstname == ".null." ? "" : user.firstname+" "+user.lastname}`}
        onClick={() => setIsOpen(!isOpen)}
        onBlur={onBlurOpen}
        className="text-primary"
      >
        <svg className="relative inline-block w-3 h-3 mr-1 -top-px" viewBox="0 0 512 512" fill="currentColor">
          <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969
            26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906
            132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906
            181.019531-74.980469 48.351563-48.351562 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-308.679687
            367.40625c10.707031-61.648438 64.128906-107.121094 127.660156-107.121094 63.535156 0 116.953125 45.472656 127.660156 107.121094-36.347656
            24.972656-80.324218 39.613281-127.660156 39.613281s-91.3125-14.640625-127.660156-39.613281zm46.261718-218.519531c0-44.886719 36.515626-81.398438
            81.398438-81.398438s81.398438 36.515625 81.398438 81.398438c0 44.882812-36.515626 81.398437-81.398438 81.398437s-81.398438-36.515625-81.398438-81.398437zm235.042969
            197.710937c-8.074219-28.699219-24.109375-54.738281-46.585937-75.078125-13.789063-12.480469-29.484375-22.328125-46.359375-29.269531 30.5-19.894531
            50.703125-54.3125 50.703125-93.363281 0-61.425782-49.976563-111.398438-111.402344-111.398438s-111.398438 49.972656-111.398438 111.398438c0 39.050781 20.203126
            73.46875 50.699219 93.363281-16.871093 6.941406-32.570312 16.785156-46.359375 29.265625-22.472656 20.339844-38.511718 46.378906-46.585937
            75.078125-44.472657-41.300781-72.355469-100.238281-72.355469-165.574219 0-124.617188 101.382812-226 226-226s226 101.382812 226 226c0 65.339844-27.882812 
            124.277344-72.355469 165.578125zm0 0"
          />
        </svg>
        <span translate="no" data-cy="username" className="hidden sm:inline-block">
          {user.firstname == ".null." ? "" : user.firstname+" "+user.lastname}
        </span>
        <svg className="inline-block w-4 h-4 ml-1" viewBox="0 0 24 24" fill="none">
          <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      <div
        tabIndex={-1}
        ref={refList}
        className={`origin-top-right absolute right-0 top-6 z-20 transition-opacity ${isOpen ? 'opacity-100 visible' : 'invisible opacity-0'}`}
        // style={{minWidth: "280px"}}
        onBlur={onBlurOpen}
      >
        <div className="relative bg-white rounded-md shadow-lg outline-none ring-1 ring-black ring-opacity-5">
          <div className="flex flex-col items-start text-sm" translate="no">
            {/* <div className="w-20">
              <img
                className="mr-2 text-gray-800"
                width="64"
                height="64"
                style={{ width: "64px", height: "64px"}}
                src={`${userProfileImage}`} />
            </div> */}
            <div className='lg:hidden'>
              {user.firstname != ".null." && (
                <div className='w-full p-4 py-2 pt-2.5 text-xs sm:hidden'>
                  {t("Zalogowany\u00A0jako")}: <br />
                  <b>{user.firstname}&nbsp;{user.lastname}</b>
                </div>
              )}
              <div className={`${user.firstname == ".null." ? "pt-2.5" : "pt-0"} w-full p-4 py-2 sm:pt-2.5 text-xs`}>
                {t('Firma')}: <br />
                <b>{user.company}</b>
              </div>
              {user.credit.limit >= 0 && (
                <div className='w-full p-4 py-2 pt-0 text-xs'>
                  {t('Limit kredytu')}: <span className='text-green-600 text'>{user.credit.limit || 0}&nbsp;zł</span>
                </div>
              )}
              <hr className="w-full h-px my-0.5 bg-transparent border-px border-dashed" />
            </div>
            <button
              type="button"
              onClick={onSettings}
              className="w-full flex items-center p-4 py-2 pt-1.5 mt-0 sm:mt-1 hover:bg-primary hover:text-white"
            >
              <svg viewBox="0 0 48 48" className="w-4 h-4 mt-px mr-1">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.89px"
                  d="M47,27V21H41.51a18.09,18.09,0,0,0-2.76-6.68l3.88-3.87L37.55,5.37,33.68,9.25A18.09,18.09,0,0,0,27,6.49V1H21V6.49a18.09
                  ,18.09,0,0,0-6.68,2.76L10.45,5.37,5.37,10.45l3.88,3.87A18.09,18.09,0,0,0,6.49,21H1v6H6.49a18.09,18.09,0,0,0,2.76,
                  6.68L5.37,37.55l5.08,5.08,3.87-3.88A18.09,18.09,0,0,0,21,41.51V47h6V41.51a18.09,18.09,0,0,0,6.68-2.76l3.87,3.88,
                  5.08-5.08-3.88-3.87A18.09,18.09,0,0,0,41.51,27Z"
                />
                <circle fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3px" cx="24" cy="24" r="9" />
              </svg>
              <span>{t('Ustawienia')}</span>
            </button>
            <hr className="w-full h-px my-0.5 bg-gray-200 border-0" />
            <button
              type="button"
              onClick={onLogout}
              className="w-full flex items-center p-4 py-2 pt-1.5 mb-1 hover:bg-red-500 text-red-500 hover:text-white "
            >
              <svg className="inline-block w-4 h-4 mt-1 mr-1" viewBox="-70 0 600 600" fill="currentColor">
                <path d="M255.15,468.625H63.787c-11.737,0-21.262-9.526-21.262-21.262V64.638c0-11.737,9.526-21.262,21.262-21.262H255.15
                  c11.758,0,21.262-9.504,21.262-21.262S266.908,0.85,255.15,0.85H63.787C28.619,0.85,0,29.47,0,64.638v382.724 c0,35.168,28.619,
                  63.787,63.787,63.787H255.15c11.758,0,21.262-9.504,21.262-21.262 C276.412,478.129,266.908,468.625,255.15,468.625z"
                />
                <path d="M505.664,240.861L376.388,113.286c-8.335-8.25-21.815-8.143-30.065,0.213s-8.165,21.815,0.213,30.065l92.385,91.173
                  H191.362c-11.758,0-21.262,9.504-21.262,21.262c0,11.758,9.504,21.263,21.262,21.263h247.559l-92.385,91.173 c-8.377,8.25-8.441,
                  21.709-0.213,30.065c4.167,4.21,9.653,6.336,15.139,6.336c5.401,0,10.801-2.041,14.926-6.124l129.276-127.575 c4.04-3.997,
                  6.336-9.441,6.336-15.139C512,250.302,509.725,244.88,505.664,240.861z"
                />
              </svg>
              <span>{t('Wyloguj')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

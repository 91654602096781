import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import useTransCustom from '../hook/transition';
import useSortData from '../hook/useSortData';
import CartSelect from '../../components/cart/cartselect';
import TSort from './tsort';

import './index.css';
import { uid } from '../util';

const TTile = ({
  keyName = '',
  sort: funcSort = null,
  defaultSort = null,
  data,
  children,
  catalog = false,
  noHeader = false,
}) => {
  const { sortedItems, requestSort, directionKey } = useSortData(data, defaultSort);
  const [saveItems, setSaveItems] = useState([]);
  const [isPending, startTrans] = useTransCustom(3000);

  useEffect(() => {
    if (sortedItems == undefined) return;

    if (sortedItems.length === 0) {
      setSaveItems([]);
      return;
    }
    const elementList = [];

    sortedItems.map((value) => (
      elementList.push(
        <div key={`ttile_${String(value[keyName])}_${uid()}`} className="flex flex-col item">
          {React.Children.map(children, (child, index) => (
            React.cloneElement(child, { data: value, tabindex: index + 1, keyName })
          ))}
        </div>,
      )
    ));

    setSaveItems(elementList);
  }, [sortedItems]);

  const handleRequestSort = (symbol, sort) => !isPending && (startTrans(() => requestSort(symbol, undefined /* type */, sort, funcSort)));

  return (
    <>
      {noHeader != true && (
        <div className="flex flex-col-reverse items-center justify-center w-full gap-2 xs:inline-flex xs:flex-row sm:justify-end xs:items-end">
          <TSort handleChange={handleRequestSort} />
          {!catalog ? <CartSelect /> : ''}
        </div>
      )}
      <section role="tile" className="product-list">
        {saveItems}
      </section>
    </>
  );
};

TTile.propTypes = {
  keyName: PropTypes.string.isRequired,
  //data: PropTypes.arrayOf,
  //children: PropTypes.arrayOf(PropTypes.element),
};

TTile.defaultProps = {
  //data: [],
};

export default TTile;

import { useEffect, useCallback, useState, Suspense, useContext } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
// import { Link } from 'react-router-dom';
import parser from 'bbcode-to-react';
import { default as storage } from '../../components/util/storage';

import LazyImage from '../../components/hook/lazyimage';
import InputCounter from '../../components/hook/inputcounter';
import CartButton from '../../components/hook/cartbutton';
import FaviButton from '../../components/hook/favibutton';

import { parseJSON, numberFormatCurrency, numberFormatQuantity, roundTo } from '../../components/util/index';

import CartContext from '../../components/context/cart.ts';
import useCartAction from '../../components/hook/cartaction';
import imgNotFound from '../../components/util/imgnotfound';

import Battery from '../../components/battery';
// import { TileLabelTag } from '../../components/labeltag';
import IconDiscount from '../../components/icon/IconDiscount';
import SSelect from '../../components/grid/sselect';

import TTile from '../../components/tile';
import TRow from '../../components/tile/trow';

import useFileCache from '../../components/hook/useFileCache';
import Link from '../../components/link';

import { GlobalStateContext } from '../../components/context/global';
import { useTranslation } from 'react-i18next';

const ArticleTile = ({ data = [], handleSort = null, handleUpdate = null, menuTreePath = null, catalog = false, noHeader = false, noDialog = false }) => {
  const { t } = useTranslation("shop");
  const [loadArticleImage] = useFileCache();

  const templateImage = (item) => {
    useEffect(() => {
      // console.log('IMAGE LOAD');
    }, [item.symbol_art]);

    return (
      <picture className="flex justify-center">
        {/* <source media="(max-width:650px)" srcSet={imgNotFound} /> */}
        <Link to={`${catalog ? '/catalog/' : '/article/'}show/${encodeURIComponent(item.symbol_art)}`}>
          <LazyImage
            className="h-30 w-30"
            src=""
            symbol={item.symbol_art}
            width="240"
            height="240"
            alt=""
            handlerLoad={(symbol) => loadArticleImage(symbol, 240)}
          />
        </Link>
      </picture>
    );
  };

  const loadArticleShow = (symbol) => {
    console.log('loadArticleShow', symbol);

    // const ArticleShow = lazy(() => import('./show2'));
    const container = document.getElementById('show-article');

    const closeArticleShow = () => {
      unmountComponentAtNode(container);
    };

    render(
      <Suspense fallback={<div>Loading...</div>}>
        {/* <ArticleShow symbol={symbol} handleExit={closeArticleShow} renderPath={menuTreePath} /> */}
      </Suspense>,
      container,
    );
  };

  const templateTitle = useCallback((item) => (
    <div className="flex-grow my-2">
      <Link to={`${catalog ? '/catalog/' : '/article/'}show/${encodeURIComponent(item.symbol_art)}`}>
        {/* <TileLabelTag data={item.wyroznienia.find(Boolean)} /> */}
        <span className="block">{parser.toReact(item.nazwa_art)}</span>
        <span className='text-sm text-gray-400'>{t(item?.label_name) || t("Symbol")}: {catalog ? item.symbol_art : item.label}
        </span>
        {item?.extra_field_label != undefined && (
          <span className='block text-sm text-gray-400'>{t(String(item.extra_field_label).toLocaleLowerCase())}: {item.extra_field}</span>
        )}
      </Link>
    </div>
  ), []);

  const templateCart = useCallback((item) => {
    if (catalog) return '';

    const defaultJM = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
    if (defaultJM === undefined) return ''; // prevent reload

    const [firstRender, setFirstRender] = useState(false);

    const [state, cartAction] = useCartAction();
    const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });
    const [count, setCount] = useState(cartAction.getProductConfirm(item));

    const globalState = useContext(GlobalStateContext);
    
    const jmList = item.jm_pom
    .filter(el => el.visible == 1)
    .map(el => ({ key: el.symbol_jmp, value: el.nazwa_jm, sort: el.podstawowa }))
    .sort((a, b) => a.sort + b.sort);
    
    const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
    const basicJM = item.jm_pom.find(el => el.podstawowa === 1);
    
    const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJM);
    
    const changeMeasure = (em) => {
      cartAction.setMeasure(item, em.value);
      setSelectedJM(item.jm_pom.find(el => el.symbol_jmp === em.value));
    };

    const [quantityMax, setQuantityMax] = useState({ current: 0, actual: 0 });

    useEffect(() => {
      // if (firstRender === false) {
      //   return false;
      // }
      // console.log('product Effect', quantity);
      cartAction.setProduct({ item, quantity });
      setQuantityMax(cartAction.getQuantityMax({
        item,
        maxActual: item.zapas.actual, 
        jmCurrent: defaultJM.symbol_jmp,
        priorityMeasure: true,
        stock_unlimit: globalState.user?.stock_unlimit
      }));
      // setCount(cartAction.getProductConfirm(item));
    }, [quantity]);

    useEffect(() => {
      if (firstRender === false) {
        return false;
      }
      setCount(cartAction.getProductConfirm(item));
    }, [state.product.event[item.symbol_art], state.select]);

    useEffect(() => {
      if (firstRender === false) {
        setFirstRender(true);
        return false;
      }
      //console.log('measure Effect');

      setQuantity({
        ...quantity,
        confirm: parseFloat(cartAction.getProduct({ item }).confirm),
        convert: parseFloat(cartAction.getProduct({ item }).convert),
        precision: parseFloat(cartAction.getProduct({ item }).precision),
      });
    // }, [state.measure[item.symbol_art], state.select, state.product.event[item.symbol_art]]);
    }, [state.measure[item.symbol_art], state.select]);

    const changeFavorite = (isOff) => {
      cartAction.setFavorite(item, isOff);
    };

    return (
      <>
        <CartContext.Provider value={[quantity, setQuantity, state.loading, state.error, state.done]}>
          {globalState.user?.favorite_display && (
            <FaviButton
              value={state.favorite[item.symbol_art]}
              onChange={changeFavorite}
              small
              defaultClassName={`absolute top-1 right-1 z-10 p-1`}
            />
          )}
          <div className="flex flex-row flex-wrap justify-center">
            <div className="flex flex-col justify-center pr-2 leading-5">
              {item.jm_pom.length > 1 && (
                <SSelect
                  className='h-9 rounded-md text-sm border pl-2 pr-8 py-1 leading-5'
                  value={jmList}
                  defaultValue={selectedJM.symbol_jmp}
                  handleChange={changeMeasure}
                />
              )}
              {selectedJM.podstawowa !== 1 && (
                <span className="block text-xs text-gray-400">
                  {`1${selectedJM.symbol_jmp}`}
                  &nbsp;=&nbsp;
                  {selectedJM.przelicz}
                  {item.symbol_jm}
                </span>
              )}
            </div>
            <InputCounter
              value={1}
              max={quantityMax.current}
              maxActual={quantityMax.actual}
              disable={item.isabstractmodel || item.cena == 0}
            />
          </div>
          <div className="flex flex-row flex-wrap justify-center pt-2">
            <div className="flex flex-col">
              <CartButton
                value={1}
                max={quantityMax.current}
                maxActual={quantityMax.actual}
                disable={item.isabstractmodel || item.cena == 0}
                defaultClassName={`${count > 0 ? 'h-7' : 'h-9'}`}
                type="big"
                noDialog={noDialog}
                templateCount={(
                  <span className={`${count > 0 ? '' : 'hidden'} flex-row block text-xs text-gray-400 leading-3 text-center relative`}>
                    {count}&nbsp;{basicJM && basicJM?.symbol_jmp}
                  </span>
                )}
              />
            </div>
          </div>
        </CartContext.Provider>
      </>
    );
  }, []);

  const templatePriceQuantity = (item) => {
    const defaultJMD = item.jm_pom.sort((a, _) => a.podstawowa == 1 && a.visible == 1 ? -1 : 1).find(el => el.visible == 1);
    if (defaultJMD === undefined) return ''; // prevent reload

    const globalState = useContext(GlobalStateContext);
    const [, cartAction] = useCartAction();
    const [state] = useCartAction();
    const defaultJM = item.jm_pom.find(el => el.podstawowa === 1);
    const defaultJMP = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);

    const [selectedJM, setSelectedJM] = useState(defaultJMP || defaultJMD);
    const [quantity, setQuantity] = useState({ ...cartAction.getProduct({ item }) });

    const jmList = item.jm_pom.filter(el => el.visible == 1);

    let template = ``;
    const showQuantityInfo = item?.outOfStock != undefined && Array.isArray(item.outOfStock) && item.outOfStock[0]?.value != undefined && item.outOfStock[0].value.trim() != "";
    const tplQuantityInfo = (
      <span className='block text-sm leading-3 text-red-400'>
        {item?.outOfStock != undefined &&  Array.isArray(item.outOfStock) && (item.outOfStock[0].desc.trim() || item.outOfStock[0].value.trim())}
      </span>
    );

    if (item.zapas.stock_show == 1) {
      template = (
        <>
          {(item.zapas.actual <= 0 && showQuantityInfo) && tplQuantityInfo}
          {!(item.zapas.actual <= 0 && showQuantityInfo) && (
            <span key="quantity_real_0">
              {numberFormatQuantity(item.zapas.actual / (selectedJM?.przelicz || 1), +selectedJM?.precision)}&nbsp;{selectedJM.symbol_jmp}
              {selectedJM?.podstawowa !== 1 && (
                <span className="block text-xs leading-3 text-gray-400">{numberFormatQuantity(item.zapas.actual, +selectedJM?.precision)}&nbsp;{defaultJM && defaultJM?.symbol_jmp}</span>
              )}
            </span>
          )}
        </>
      );
    }

    if (item.zapas.stock_show == 2) { // dostępny/niedostępny
      template = (
        <span className="block text-sm" key="quantity_available_0">
          {item.zapas.actual == 1 ? t('dostępny') : showQuantityInfo ? tplQuantityInfo : t('niedostępny')}
        </span>
      );
    }

    if (item.zapas.stock_show == 3) { // pasek graficzny
      template = (
        <>
          {(item.zapas.actual <= 0 && showQuantityInfo) && tplQuantityInfo}
          {!(item.zapas.actual <= 0 && showQuantityInfo) && (
            <Battery value={item.zapas.actual} min={item.zapas.min} max={item.zapas.max} key="quantity_battery_0" />
          )}
        </>
      );
    }

    useEffect(() => {
      // console.log('currency', String(+item.cena * parseFloat(selectedJM.przelicz)), item.cena, parseFloat(selectedJM.przelicz));

      const jm = item.jm_pom.find(el => el.symbol_jmp === state.measure[item.symbol_art]);
      if (jm !== undefined) setSelectedJM(jm);

      setQuantity({ ...cartAction.getProduct({ item }) });
    }, [state.measure[item.symbol_art], state.select]);

    return (
      <>
        
          <div className="flex flex-row mt-2">
            <div className="justify-start flex-grow">
              {globalState.user?.price_catalog === true && +item?.base_price > 0 && roundTo(+item.base_price * parseFloat(selectedJM.przelicz), 2) > roundTo((+item.cena || +item.cena_n) * parseFloat(selectedJM.przelicz), 2) && (
                <>
                  {globalState.user?.rabat_procent === true && (
                    <span className='block text-sm leading-3 text-red-400'>
                      {/* {roundTo(((+item.base_price) - (+item.cena || +item.cena_n)) / (+item.cena || item.cena_n) * 100 , 2)}% */}
                      {roundTo(100 * ((+item.base_price - (+item.cena || item.cena_n)).toFixed(2) / +item.base_price).toFixed(2), 2)}%
                    </span>
                  )}
                  {globalState.user?.rabat_procent !== true && (
                    <span className='block text-sm leading-3 text-red-400 line-through'>
                      {numberFormatCurrency(+item.base_price * parseFloat(selectedJM.przelicz), item.currency)}
                    </span>
                  )}
                </>
              )}
              <span className="block text-xl text-red-400 md:whitespace-nowrap">
                <span className="flex items-center justify-start gap-1">
                  {quantity.price_discount > 0 && roundTo(+quantity.price_discount, 2) !== roundTo(item.cena, 2) && (
                    <span>
                      <IconDiscount />
                    </span>
                  )}
                  {item.cena_n != undefined && (
                    <span translate="0">
                      {+item.cena ?
                        +item.cena !== 0 ? numberFormatCurrency((+quantity.price_discount || +item.cena) * parseFloat(selectedJM.przelicz), item.currency) : '-,--'
                        : numberFormatCurrency((+quantity.price_discount || +item.cena_n) * parseFloat(selectedJM.przelicz), item.currency)
                      }
                      {((item.jm_pom.length > 1 && (defaultJM && defaultJM?.symbol_jmp) != selectedJM?.symbol_jmp) || (item.jm_pom.length > 1 && jmList.length <= 1)) && (
                        <>&nbsp;/&nbsp;<span className="inline-block">{selectedJM.symbol_jmp}</span></>
                      )}
                    </span>
                  )}
                </span>
              </span>
              <span className={`${selectedJM.podstawowa !== 1 ? '' : 'hidden'} text-xs text-gray-400 leading-3 block md:whitespace-nowrap`} translate="0">
                {+item.cena !== 0 ? numberFormatCurrency(+item.cena, item.currency) : '-,--'}&nbsp;/&nbsp;<span className="inline-block">{defaultJM && defaultJM?.symbol_jmp}</span>
              </span>
            </div>
            {!catalog && (
              <div className="justify-end flex-shrink text-right">
                {template}
              </div>
            )}
          </div>
      </>
    );
  };

  const getSort = () => {
    let sortObj = {};
    const sessionSort = storage.get('local-sort-mode');

    if (sessionSort) {
      sortObj = JSON.parse(sessionSort);
      return ({ func: 1, key: sortObj.symbol, direction: sortObj.sort, type: undefined });
    }
    return null;
  };

  return (
    <>
      <TTile keyName="symbol_art" data={data} sort={handleSort} noHeader={noHeader} defaultSort={getSort()} catalog={catalog}>
        <TRow symbol="image" template={templateImage} />
        <TRow symbol="name" template={templateTitle} />
        <TRow symbol="cart" template={templateCart} />
        <TRow symbol="price" template={templatePriceQuantity} />
      </TTile>
    </>
  );
};

export default ArticleTile;

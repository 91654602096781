import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import api from '../../service/api.ts';
import { debounce, parseJSON, uid } from '../../util/index';
import Tree2 from './tree2';
// import CustomScroll from 'react-custom-scroll';
import { SpinnerAbsolute } from '../../spinner';

import './style.css';
import './scroll.css';
import { useTranslation } from 'react-i18next';
import { GlobalStateContext } from '../../context/global.js';
// import { GlobalDispatchContext, GlobalStateContext } from '../../context/global';

const getCategories = async (catalog = false) => {
  const response = await (catalog ? api.getCatalogCategories({ kat: '' }) : api.getCategories({ kat: '' }));
  return response?.data;
};

export default function useMenuTree({ handleCategory, newNode = [''], catalog = false }) {
  // const globalContext = useContext(GlobalDispatchContext);
  const globalState = useContext(GlobalStateContext);

  const { t } = useTranslation("shop");
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState(false);
  const [expand, setExpand] = useState(true);
  const refCategory = useRef(null);
  refCategory.current = categoryList;

  let isMounted = false;

  const handleExpand = () => {
    setExpand(!expand);
  };

  const checkIsLoaded = () => {
    const loopTimeout = (resolve) => {
      setTimeout(() => {
        if (refCategory.current !== false) {
          resolve();
        } else
          loopTimeout(resolve);
      }, 100);
    };

    return new Promise((resolve) => {
      loopTimeout(resolve);
    });
  };

  const handlePath = async (node) => {
    await checkIsLoaded();

    let nodeTmp = node[0];
    const pathList = [];

    if (refCategory.current === false) return ({});

    [...refCategory.current].reverse().forEach((val) => {
      if (val.node_key === nodeTmp.trim()) {
        pathList.push({ [val.text]: `#${val.node_key}` });
        nodeTmp = val.parent;
      }
    });

    // console.log("%cNOEEEEEE", "color:red", node[0], categoryList);
    // if (globalState.category != node[0])
    //   globalContext.setCategory(node[0]);

    return pathList
      .reverse()
      .reduce((acc, obj) => {
        const [entries] = Object.entries(obj);
        return { ...acc, [String(entries[0])]: entries[1] };
      }, {});
  };

  useEffect(() => {
    isMounted = true;
    setLoading(true);

    getCategories(catalog)
      .then(r => parseJSON(r?.dane))
      .then(data => {
        // let dataMap = [];
        // if (data.length > 0) {
        //   dataMap = data.map((el) => (
        //     {
        //       ...el,
        //       node_key: el.node_key.replace(/ /g, ''),
        //       parent: el.parent.replace(/ /g, ''),
        //     }
        //   ));
        // }

        // setCategoryList(dataMap);
        if (isMounted) setCategoryList([...data] || []);
      }).catch(err => {
        if (isMounted) setCategoryList([]);
        console.log("CAT err", err);
      }).finally(() => {
        if (isMounted) setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [globalState.lang]);

  useEffect(() => {
    // console.log("%cnewNode", "color:pink", newNode, decodeURI(newNode));
    onChangeCategory(newNode);
  }, [newNode]);

  const onChangeCategory = useCallback(
    debounce((node) => {
      if (typeof handleCategory != 'function') return;
      const nodeParsed = node.map(n => n.split('-').map(nn => nn.padEnd(3).trimStart()).join('-'));
      handleCategory(nodeParsed);
    }, 50),
    [],
  );

  const renderMenu = (
    <div className=''>
      {loading && (
        <div className={`${categoryList ? '' : 'relative h-12 w-36' } w-full min-h-full overflow-hidden`}>
          <SpinnerAbsolute noShadow />
        </div>
      )}
      <button
        type="button"
        className="flex items-center justify-between w-full px-4 py-3 pr-6 text-gray-600 cursor-pointer lg:hidden hover:bg-gray-100 hover:text-gray-700 focus:outline-none"
        onClick={handleExpand}
      >
        <span className="flex items-center">
          <svg className="w-4 h-4" viewBox="0 -52 512 512">
            <path d="m0 0h113.292969v113.292969h-113.292969zm0 0" />
            <path d="m149.296875 0h362.703125v113.292969h-362.703125zm0 0" />
            <path d="m0 147.007812h113.292969v113.292969h-113.292969zm0 0" />
            <path d="m149.296875 147.007812h362.703125v113.292969h-362.703125zm0 0" />
            <path d="m0 294.011719h113.292969v113.296875h-113.292969zm0 0" />
            <path d="m149.296875 294.011719h362.703125v113.296875h-362.703125zm0 0" />
          </svg>
          <span className="mx-4 font-medium">{t('Kategorie')}</span>
        </span>
        <span className="mr-1">
          <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
            <path className={`${!expand ? '' : 'hidden'}`} d="M9 5L16 12L9 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path className={`${expand ? '' : 'hidden'}`} d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
      </button>
      <nav className={`${expand ? 'flex' : 'hidden'} pl-6 py-2 text-sm`}>
        {/* style={{ maxHeight: '100%' }} <CustomScroll flex="1" allowOuterScroll={true}> */}
        {categoryList && (
          <Tree2 nodes={categoryList || []} onHandler={(node) => onChangeCategory(node)} selected={newNode} catalog={catalog} />
        )}
        {/* </CustomScroll> */}
      </nav>
    </div>
  );

  return [renderMenu, handlePath];
}

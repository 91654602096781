import { render, unmountComponentAtNode } from 'react-dom';

import Dialog from './dialog';
import Button from './button';
import Portal from '../context/portal';
import Translate from "../translate/Translate";

const AlertDialog = function({
  key, 
  title, 
  message = '', 
  onClose = () => {}, 
  color = 'red', 
  autoClose = 0, 
  portal = false, 
  html = false,
}) {
  const container = document.getElementById('modal-container');

  if (key != '' && container.querySelector(`div[data-key='${key}']`)) 
    return false;

  const getColorText = function(color) {
    if (color == "yellow" || color == "white")
      return "text-black"

    return "text-white";
  }

  const getColorBorder = function(color) {
    if (color == "white")
      return "border border-black"

    return "";
  }

  // const { title, message, onClose } = props;
  const timeoutClose = autoClose > 0 && setTimeout(() => onCloseAlert(), autoClose);
  const colorText = getColorText(color);
  const borderColor = getColorBorder(color);
  let bgColor = 'bg-white';

  switch (color) {
    case "pink": bgColor = "bg-pink-400"; break;
    case "red": bgColor = "bg-red-400"; break;
    case "green": bgColor = "bg-green-400"; break;
    case "blue": bgColor = "bg-blue-400"; break;
    case "indigo": bgColor = "bg-indigo-400"; break;
    case "yellow": bgColor = "bg-yellow-400"; break;
    case "orange": bgColor = "bg-orange-400"; break;
  }

  const onCloseAlert = () => {
    timeoutClose > 0 && clearTimeout(timeoutClose);

    setTimeout(() => {
      onClose();
      portal == false && unmountComponentAtNode(container);
    }, 0);
  };

  if (portal == true)
    return (
      <Portal elementId="modal-container">
        <Dialog open onClose={onCloseAlert} bgColor={bgColor} iconColor={colorText} dialogKey={key}>
          <div className="p-4 pb-6">
            <h2 className={`text-xl ${colorText}`}>
              <Translate ns="dialog" value={title} />
            </h2>
            <div className={`pt-5 break-words ${colorText} whitespace-pre`}>
              {(typeof message == "string" && !html) && (
                <Translate ns="dialog" value={message.replaceAll("\n","\a")} />
              )}
              {(typeof message == "string" && html) && (
                <div dangerouslySetInnerHTML={{ __html: message }} />
              )}
              {(typeof message != "string") && (
                message
              )}
            </div>
          </div>
          <div className="flex justify-end p-3 bg-gray-100 border-t border-gray-200 border-dotted rounded-b-lg">
            <div className="p-1">
              <Button className={`${bgColor} ${colorText} ${borderColor}`} onClick={() => onCloseAlert()}>
                <Translate ns="dialog" value={'Zamknij'} />
              </Button>
            </div>
          </div>
        </Dialog>
      </Portal>
    );

  container && render(
    <Dialog open onClose={onCloseAlert} bgColor={bgColor} iconColor={colorText} dialogKey={key}>
      <div className="p-4 pb-6">
        <h2 className={`text-xl ${colorText}`}>
          <Translate ns="dialog" value={title} />
        </h2>
        <div className={`pt-5 break-words ${colorText}`}>
          {(typeof message == "string"&& !html) && (
            <Translate ns="dialog" value={message.replaceAll("\n","\a")} />
          )}
          {(typeof message == "string" && html) && (
            <div dangerouslySetInnerHTML={{ __html: message }} />
          )}
          {(typeof message != "string") && (
            message
          )}
        </div>
      </div>
      <div className="flex justify-end p-3 bg-gray-100 border-t border-gray-200 border-dotted rounded-b-lg">
        <div className="p-1">
          <Button className={`${bgColor} ${colorText} ${borderColor}`} onClick={() => onCloseAlert()}>
            <Translate ns="dialog" value={'Zamknij'} />
          </Button>
        </div>
      </div>
    </Dialog>,
    container,
  );
};

export default AlertDialog;
